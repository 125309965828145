import React from "react";
import ReactMarkdown from "react-markdown";
const gfm = require("remark-gfm");

const BodyContentRender = ({ markdown, className }) => {
  return (
    <ReactMarkdown
      className={className ? className : "markdown-styles"}
      plugins={[gfm]}
      children={markdown}
      allowDangerousHtml
    />
  );
};

export default BodyContentRender;
